.sidebar {
    position: fixed;
    left: 0rem;
    top: 5.6rem;
    bottom: 1rem;
    background: #DADFE0;
    display: flex;
    flex-direction: column;
    transition: width 0.3s ease-in;
    height: 90vh;
    z-index: 3;
}

.btn_toggle {
    position: absolute;
    width: 25px;
    height: 25px;
    padding: 0;
    top: 5px;
    right: 0;
    transform: translateX(50%);
    border-radius: 50%;
    background: #2b2b2b;
    border: 0;
    cursor: pointer;
}

.logo {
    text-align: center;
}

.logo a,
.anc {
    display: inline-block;
    padding: 1rem 0 0;
}

.menus {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    overflow-y: auto;
    width: 150px;
}

.top_menu,
.bottom_menu {
    width: 100%;
}

ul.menu {
    list-style: none;
    padding: 0;
    margin: 0;
}

ul.menu li a,
.anc {
    padding: 0.8rem 1.6rem;
    padding-right: 0;
    display: inline-flex;
    align-items: center;
    color: black;
    text-decoration: none;
    font-size: 1rem;
    width: auto;
    transition: background 0.3s ease-in-out;
}

.anc {
    padding: 0.8rem 0.4rem;
}

ul.menu li a.linkes:hover {
    /* background: white; */
}

ul.menu li img {
    width: 28px;
}

.sidebar ul.menu li a span {
    display: inline-block;
    transform-origin: 0 0;
    transition: opacity 0.3s ease-in;
}

.sidebar.isShrinked {
    transition: ease-in-out 0.3s;
}

.sidebar.isShrinked .btn_toggle svg {
    transform: rotate(180deg);
}

.sidebar ul.menu li a span {
    display: none;
}

.sidebar.isShrinked ul.menu li a span {
    display: inline-block;
}



@media (min-width:578px) and (max-width:700px) {
    .sidebar ul.menu li a span {
        display: none;
    }

    .sidebar ul.menu li a img {
        position: relative;
        left: -20px;
    }

    .sidebar ul.menu li a span {
        position: relative;
        left: -23px;
    }
}

@media screen and (max-width:578px) {
    .sidebar ul.menu li a span {
        display: none;
    }

    .sidebar.isShrinked ul.menu li a span {
        position: relative;
        left: -23px;
    }

    .sidebar ul.menu li a img {
        display: none;

    }
}