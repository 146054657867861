.App {
  text-align: center;
}

.main {
  background-image: linear-gradient(top, #E1E5E5, bottom, #F3F5F5);
  /* transition: ease-in-out; */
  /* transition-duration: 35s; */
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.sidebar.isShrinked,
.sidebar-width1 {
  width: 15%;
}

.sidebar,
.sidebar-width2 {
  width: 8%;
}

.main-width1 {
  width: 85%;
}

.main-width2 {
  width: 92%;
}

@media (min-width:479px) and (max-width:900px) {

  .sidebar.isShrinked,
  .sidebar-width1 {
    width: 20%;
  }

  .sidebar,
  .sidebar-width2 {
    width: 12%;
  }

  .main-width1 {
    width: 80%;
  }

  .main-width2 {
    width: 88%;
  }
}

@media (min-width:300px) and (max-width:480px) {

  .sidebar.isShrinked,
  .sidebar-width1 {
    width: 25%;
  }

  .sidebar,
  .sidebar-width2 {
    width: 12%;
  }

  .main-width1 {
    width: 75%;
  }

  .main-width2 {
    width: 88%;
  }
}


/* Background color and card  all page CSS Start  */
.passwordMain,
.profile,
.collection,
.collheight,
.property,
.report {
  background-image: linear-gradient(#E2E5E6, #F5F6F7);
  width: 100%;
}

.app-sidebar {
  transition-duration: 0.4s;
  transition: ease-in
}

.collection-card,
.property-card,
.profile-card,
.report-card {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  background-color: #E2E5E6;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0.3, 0.3, 0.3, 0.5);
}

/* Background color all page CSS End  */


/* login page CSS Start  */
.background {
  width: 100vw;
  height: 100vh;
  background-image: url("../public/assets/images/auth/loginBackgroung.jpeg");
  background-size: cover;
  background-position-x: center;
  background-repeat: no-repeat;

  form {
    width: 300px;
    padding: 20px;
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 10px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.3);
  }
}

@media (min-width: 300px) and (max-width:800px) {
  .background {
    background-image: linear-gradient(#ED9645, #E9636F);
    background-size: cover;
  }
}

/* login page CSS End  */

/* Change Password Page CSS Start */
.passwordMain {
  height: 80vh;

  form {
    min-width: 300px;
  }

  input {
    min-width: 240px;
    margin-bottom: 20px;
    padding: 10px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    background-color: #F5F6F7;
    color: #333;
    transition: background-color 0.3s ease;
  }
}

/* Change Password Page CSS End  */

/* Profile Page CSS Start */
.profile {
  height: 80vh;
}

.profile-card {
  height: 170px;
  max-width: 800px;
}

@media (min-width:300px) and (max-width:770px) {
  .profile-card {
    height: 300px;
  }

  .line-profile {
    display: none;
  }

}

.profile-details,
.profile-roles {
  flex: 1;
  margin: 0 10px;
}

.profile-details p,
.profile-roles p {
  font-size: 16px;
  line-height: 1.6;
  margin: 8px 0;
  color: #4a4a4a;
}

strong {
  color: #2b2b2b;
  font-weight: 600;
}

.profile-card .profile-roles {
  text-align: left;
}

@media (max-width: 768px) {
  .profile-card {
    flex-direction: column;
    text-align: left;
  }

  .profile-details,
  .profile-roles {
    margin: 10px 0;
  }
}

/* Profile Page CSS End */


/* Collection Page CSS  Start */
.collection {
  height: 70vh;
}

.collheight {
  height: 80vh;
}

.collection-card {
  /* margin:  auto; */
  max-width: 1000px;
}

.collection-details,
.collection-roles {
  flex: 1;
  margin: 0 10px;
}

.collection-details p,
.collection-roles p {
  font-size: 16px;
  line-height: 1.6;
  margin: 8px 0;
  color: #4a4a4a;
}

strong {
  color: #2b2b2b;
  font-weight: 600;
}

.collection-card .collection-roles {
  text-align: left;
}

@media (max-width: 768px) {
  .collection-card {
    flex-direction: column;
    text-align: left;
  }

  .collection-details,
  .collection-roles {
    margin: 10px 0;
  }
}

/* Collection Page CSS  End*/


/* Property Page CSS  Start*/
.property {
  height: auto;
}

.search-box {
  min-width: 200px;
}

.property-card {
  max-width: 1050px;
}

.collection-details,
.collection-roles {
  flex: 1;
  margin: 0 10px;
}

.collection-details p,
.collection-roles p {
  font-size: 16px;
  line-height: 1.6;
  margin: 8px 0;
  color: #4a4a4a;
}

strong {
  color: #2b2b2b;
  font-weight: 600;
}

.collection-card .collection-roles {
  text-align: left;
}

@media (max-width: 768px) {
  .collection-card {
    flex-direction: column;
    text-align: left;
  }

  .collection-details,
  .collection-roles {
    margin: 10px 0;
  }
}

/* Property Page CSS  End*/


/*Report Page css Start*/
.report {
  height: auto;
  min-height: 80vh;
}

.report-card {
  width: 90%;
  min-width: 250px;
}

/* Report Details Start */
.mix-empty-img-enlar {
  z-index: 100;
  position: fixed;
  top: 34%;
  left: 47%;
  height: 100vh;
  width: 100vw;
  transform: translate(-50%, -50%) scale(3);
  transition: transform 0.3s ease;
}

.rotate-button {
  position: relative;
  width: auto;
  padding: 6px 11px;
  border-radius: 20px;
  display: none;
  border: none;
  position: fixed;
  right: 0;
  top: 14%;
  left: 90%;
  margin-right: 10px;
  z-index: 200;

}

.mix-empty-img-enlar~.rotate-button {
  display: block;
}

/* Report Details End */

/*Report Page css End*/

.mob-size {
  display: none;
}

@media (min-width:300px) and (max-width:650px) {

  .full-size {
    display: none;
  }

  .mob-size {
    display: block;
  }

}