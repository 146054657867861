/* Add this in PropertyDetails.css */

.transition-row {
    max-height: 0;
    /* Start hidden */
    opacity: 0;
    /* Start invisible */
    overflow: hidden;
    transition: max-height 5s ease, opacity 5s ease;
}

.transition-row.expanded {
    max-height: 500px;
    /* Adjust as per your content's height */
    opacity: 1;
}