.navbar {
  position: sticky;
  top: 0;
  left: 0;
  display: flex;
  z-index: 10;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.dropdown-container {
  position: relative;
}

.dropdown-menu {
  min-width: 150px;
  border-radius: 5px;
  overflow: hidden;
}

.dropdown-menu ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.dropdown-menu li {
  padding: 10px 15px;
}

.dropdown-menu li:hover {
  background-color: #f1f1f1;
}

.dropdown-item {
  text-decoration: none;
  color: #333;
}

.dropdown-item:hover {
  color: #000;
}